<template>
  <div class="readme-article">
    <div id="招生"></div>
    <h1 id="线索管理">
      一、线索管理
    </h1>
    <h2 id="招生线索管理">1.招生线索管理</h2>
    <h3 id="线索列表">1.1线索列表</h3>
    <p>记录已经入库的所有招生线索，可通过关键词，来源渠道，时间等条件查询线索，持续的跟进可促进线索转化为正式学员</p>
    <p>
      <img src="@/assets/img/student/1-1.png">
    </p>
    <h3 id="线索操作">1.2线索操作</h3>
    <p>可根据线索情况进行线索详情查看，详情资料编辑，提交转正申请，提交定金申请以及删除等操作，线索详情上方也有一些相应操作，例如切换校区，放入公海，锁定，静默等</p>
    <p>
      <img src="@/assets/img/student/1-2.png">
    </p>
    <p>
      <img src="@/assets/img/student/1-2-1.png">
    </p>
    <h3 id="线索回访">1.3线索回访</h3>
    <p>线索详情下方可添加回访记录，记录下次联系时间，线索人意向和备注内容</p>
    <p>
      <img src="@/assets/img/student/1-3.png">
    </p>
    <h2 id="添加招生线索">2.添加招生线索</h2>
    <p>系统支持人工添加线索，在获取到线索信息后可添加到招生线索中，记录信息，一体化跟进回访，提高成交率</p>
    <p>
      <img src="@/assets/img/student/1-4.png">
    </p>
    <h2 id="线索池">3.线索池</h2>
    <h2 id="线索公海">4.线索公海</h2>
    <h3 id="公海概述">4.1公海概述</h3>
    <p>线索公海又指公共线索，当前在跟进的线索可手动放入公海或在满足设定的规则时自动掉入公海，所有员工都可以在公海中领取线索，成为该线索的新负责人</p>
    <p>
      <img src="@/assets/img/student/1-5.png">
    </p>
    <h3 id="公海规则调配">4.2公海规则调配</h3>
    <p>线索公海规则需要先行配置，配置位置：招生-->线索调配管理-->线索回收规则/公海领取规则/线索锁定规则</p>
    <p>
      <img src="@/assets/img/student/1-6.png">
    </p>
    <h2 id="活跃线索">5.活跃线索</h2>
    <p>活跃线索指近期有回访的招生线索</p>
    <h2 id="已报名线索">6.已报名线索</h2>
    <p>已报名线索指已成功转正成为正式学员的线索</p>
    <h2 id="二访线索">7.二访线索</h2>
    <p>二访线索指通过各大平台渠道第二次引入到线索中的线索</p>
    <h2 id="静默线索">8.静默线索</h2>
    <p>静默线索又指黑名单线索，员工可手动对那些反感客户，成交率低的线索放入静默线索，静默线索不能回访，线索招生专员和接待人都将清空，在招生线索的详情页上方可设置</p>
    <p>
      <img src="@/assets/img/student/1-2-1.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Student1-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>